import api from '../../utils/services/api';

import { pixKeysActionLoading, pixKeysActionSuccess, pixKeysActionError } from '../ducks/pixKeys';

export const pixKeysFetchAction = (filter = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(pixKeysActionLoading());
    console.log('filter', filter)

    try {
      const response = await api.get(`admin/pix/listar_chaves`, {params: filter});
      dispatch(pixKeysActionSuccess(response));
    } catch (error) {
      dispatch(pixKeysActionError(error?.response?.data));
    }
  };
};
