import api from '../../utils/services/api';

import {
  detailsPixKeysDonatedActionError,
  detailsPixKeysDonatedActionLoading,
  detailsPixKeysDonatedActionSuccess,
  pixKeysDonatedActionError,
  pixKeysDonatedActionLoading,
  pixKeysDonatedActionSuccess
} from '../ducks/pixKeysDonated';

export const pixKeysDonatedFetchAction = (filter) => {
  return async (dispatch) => {
    dispatch(pixKeysDonatedActionLoading());

    try {
      const response = await api.get(`admin/pix/reivindicacao/recebidas`, {
        params: {
          ...filter
        }
      });
      dispatch(pixKeysDonatedActionSuccess(response));
    } catch (error) {
      dispatch(pixKeysDonatedActionError(error?.response?.data));
    }
  };
};

export const pixKeyDonatedDetailsFetchAction = (idClaim) => {
  return async (dispatch) => {
    dispatch(detailsPixKeysDonatedActionLoading());

    try {
      const response = await api.get(
        `/admin/pix/reivindicacao/consultar/resposta/doador?ID_SOLICITACAO=${idClaim}`,
      );
      dispatch(detailsPixKeysDonatedActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixKeysDonatedActionError(error?.response?.data));
    }
  };
};
