import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import PixKeys from './tabContent/PixKeys';
import PixTransactions from './tabContent/PixTransactions';
import { useDispatch } from 'react-redux';
import { pixTransactionsFetchAction } from '../../store/fetchActions/pixTransactionsActions';
import { pixKeysFetchAction } from '../../store/fetchActions/pixKeysActions';
import PixLimitRequest from './tabContent/PixLimitiRequest';
import { pixLimitRequestFetchAction } from '../../store/fetchActions/pixLimitRequestActions';
import PixKeyClaim from './tabContent/PixKeyClaim';
import { pixClaimKeysReceiverFetchAction } from '../../store/fetchActions/pixKeysClaimActions';
import PixKeyDonor from './tabContent/PixKeyDonor';
import { pixKeysDonatedFetchAction } from '../../store/fetchActions/pixKeysDonatedActions';

export default function GerenciarClientes() {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Transações', 'Chaves', 'Solicitação de limite', 'Reivindicação de chaves', 'Chaves doadas'];

  useEffect(() => {
    if (tabIndex === 0) dispatch(pixTransactionsFetchAction());
    if (tabIndex === 1) dispatch(pixKeysFetchAction());
    if (tabIndex === 2) dispatch(pixLimitRequestFetchAction());
    if (tabIndex === 3) dispatch(pixClaimKeysReceiverFetchAction());
    if (tabIndex === 4) dispatch(pixKeysDonatedFetchAction());
  }, [tabIndex]);

  return (
    <Wrapper title="Pix - Transações/Chaves">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" margin="1.5vw 0" />
      {(() => {
        switch (tabIndex) {
          case 0:
            return <PixTransactions value={tabIndex} index={0} />;
          case 1:
            return <PixKeys value={tabIndex} index={1} />;
          case 2:
            return <PixLimitRequest value={tabIndex} index={2} />;
          case 3:
            return <PixKeyClaim value={tabIndex} index={3} />;
          case 4:
            return <PixKeyDonor value={tabIndex} index={4} />;
        }
      })()}
    </Wrapper>
  );
}
