import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixKeysClaim: [],
  errorPixKeysClaim: false,
  errorLoadingPixKeysClaim: false,
  errorMessagePixKeysClaim: '',
  loadingPixKeysClaim: false,
  paginationPixKeysClaim: {},
  detailsPixKeysClaim: {},
  loadingDetailsPixKeysClaim: false,
  messageDetailsPixKeysClaim: '',
  successDetailsPixKeysClaim: false,
};

export const pixKeysClaimActionLoading = createAction('PIX_KEYS_CLAIM_ACTION_LOADING');
export const pixKeysClaimActionSuccess = createAction('PIX_KEYS_CLAIM_ACTION_SUCCESS');
export const pixKeysClaimActionError = createAction('PIX_KEYS_CLAIM_ACTION_ERROR');

export const detailsPixKeysClaimActionLoading = createAction('DETAILS_PIX_KEYS_CLAIM_ACTION_LOADING');
export const detailsPixKeysClaimActionSuccess = createAction('DETAILS_PIX_KEYS_CLAIM_ACTION_SUCCESS');
export const detailsPixKeysClaimActionError = createAction('DETAILS_PIX_KEYS_CLAIM_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixKeysClaimActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixKeysClaim: true,
      errorPixKeysClaim: false,
    };
  },
  [pixKeysClaimActionSuccess.type]: (state, payload) => {
    const { dados, pagination } = payload?.payload?.data;
    return {
      ...state,
      loadingPixKeysClaim: false,
      errorPixKeysClaim: false,
      dataPixKeysClaim: dados,
      paginationPixKeysClaim: pagination,
    };
  },
  [pixKeysClaimActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixKeysClaim: false,
      errorPixKeysClaim: true,
      errorMessagePixKeysClaim: payload?.mensagem,
      dataPixKeysClaim: [],
      paginationPixKeysClaim: {},
    };
  },
  [detailsPixKeysClaimActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailsPixKeysClaim: true,
    };
  },
  [detailsPixKeysClaimActionSuccess.type]: (state, payload) => {
    console.log('payload', payload)
    return {
      ...state,
      loadingDetailsPixKeysClaim: false,
      successDetailsPixKeysClaim: true,
      detailsPixKeysClaim: payload?.payload?.data.data,
    };
  },
  [detailsPixKeysClaimActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixKeysClaim: false,
      detailsPixKeysClaim: {},
      successDetailsPixKeysClaim: false,
      messageDetailsPixKeysClaim: payload?.mensagem,
    };
  },
});
