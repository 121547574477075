import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Filter from '../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import TextField from '../../../components/TextField';
import DateInput from '../../../components/DateInput';
import SelectOptions from '../../../components/SelectOptions';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import Table from '../../../components/Table/Table';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { useTheme } from 'styled-components';
import moment from 'moment';
import {
  pixClaimKeyReceiverDetailsFetchAction,
  pixClaimKeysReceiverFetchAction,
} from '../../../store/fetchActions/pixKeysClaimActions';
import api from '../../../utils/services/api';
import Modal from '../../../components/Modal/Modal';
import { translateMessage } from '../../../utils/functions/translateMessage';
import InformationClaimKeyPix from '../components/InformationClaimKeyPix';
import getDateH from '../../../utils/functions/formatDateHora';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import { Grid } from '@mui/material';

const PixKeyClaim = () => {
  const theme = useTheme();

  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    LIMIT: 10,
    TIPO: '',
    STATUS: '',
  });
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [status, setStatus] = useState([]);
  const [typePortability, setTypePortability] = useState([]);
  const { handleSubmit, control } = useForm({});
  const dispatch = useDispatch();

  const {
    dataPixKeysClaim,
    loadingPixKeysClaim,
    errorLoadingPixKeysClaim,
    paginationPixKeysClaim,
    errorMessagePixKeysClaim,
    detailsPixKeysClaim,
    messageDetailsPixLimitRequest,
    loadingDetailsPixKeysClaim,
    successDetailsPixKeysClaim,
  } = useSelector((state) => state.pixKeysClaim);
  useCallFilter(filterData, setFilter, pixClaimKeysReceiverFetchAction, 0, 0);

  const filterTable = (data) => {
    setFilterData(data);
  };

  const fecthDetailsClaim = (id) => {
    dispatch(pixClaimKeyReceiverDetailsFetchAction(id));
    setIsModalInfoOpen(true);
  };

  useEffect(async () => {
    let [status, typePortability] = await Promise.all([
      api.get('/admin/pix/tipos/status/reivindicacao'),
      api.get('/admin/pix/tipos/portabilidade'),
    ]);

    status = [...status.data.data, { id: 0, description: 'Todos' }];
    typePortability = [...typePortability.data.data, { id: 0, description: 'Todos' }];

    setTypePortability(typePortability ?? []);
    setStatus(status ?? []);
  }, []);

  const formatKeyByType = (key, typeKey) => {
    if (typeKey === 'CPF') {
      return formatCPF(key);
    }
    if (typeKey === 'CNPJ') {
      return formatCNPJ(key);
    }

    return key;
  };

  const body = dataPixKeysClaim?.map((item) => {
    return {
      data: [
        { info: getDateH(item.atualizacao), align: 'center' },
        { info: formatKeyByType(item.chave, item.tipo_chave_traduzida), align: 'left' },
        { info: item.tipo_chave_traduzida, align: 'center' },
        { info: item.status, align: 'center' },
        { info: item.tipo_solicitacao, align: 'center' },
      ],
      onClick: () => fecthDetailsClaim(item.id_pix_reivindicacao),
    };
  });

  const header = [
    {
      id: 'data de registro',
      name: 'DATA',
      align: 'center',
    },
    {
      id: 'chave',
      name: 'CHAVE',
      align: 'left',
    },
    {
      id: 'tipo chave',
      name: 'TIPO CHAVE',
      align: 'center',
    },
    {
      id: 'status',
      name: 'STATUS',
      align: 'center',
    },
    {
      id: 'tipo solicitacao',
      name: 'TIPO SOLICITAÇÃO',
      align: 'center',
    },
  ];

  return (
    <>
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              {/* <TextField label="Nome ou CPF/CNPJ" name={'search'} width={'25.042vw'} control={control} /> */}
              {/* <TextField label="Chave" name={'chave'} width={'20vw'} control={control} /> */}
              <Grid container gap={4}>
                <SelectOptions
                  label="Tipo de solicitação"
                  name={'TIPO'}
                  width={'10vw'}
                  control={control}
                  options={typePortability}
                  initialValue={0}
                />
                <SelectOptions
                  label="Status"
                  name={'STATUS'}
                  width={'10vw'}
                  control={control}
                  options={status}
                  initialValue={0}
                  keyValue={'description'}
                />
              </Grid>
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
          </form>
        </Filter>
        {loadingPixKeysClaim ? (
          <LoadingContainer>
            <CircularProgress size={'5vw'} color="inherit" />
          </LoadingContainer>
        ) : (
          <Table
            loading={loadingPixKeysClaim}
            error={errorLoadingPixKeysClaim}
            hasPagination={true}
            filter={filter}
            pagination={paginationPixKeysClaim}
            setFilter={setFilter}
            errorMessage={errorMessagePixKeysClaim}
            filterTable={filterTable}
            body={body}
            header={header}
            limit={filter.LIMIT}
          />
        )}
        <Modal
          open={isModalInfoOpen}
          close={() => setIsModalInfoOpen(false)}
          title="Informações da solicitação"
          width={'47.889vw'}
        >
          {loadingDetailsPixKeysClaim ? (
            <LoadingContainer marginTop={'1vw'}>
              <CircularProgress size={'5vw'} color="inherit" />
            </LoadingContainer>
          ) : !successDetailsPixKeysClaim ? (
            <h1>{translateMessage(messageDetailsPixLimitRequest)}</h1>
          ) : (
            <InformationClaimKeyPix
              data={detailsPixKeysClaim ?? []}
              idTransaction={detailsPixKeysClaim.id_pix_reivindicacao}
              showButton={false}
              onClose={() => setIsModalInfoOpen(false)}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default PixKeyClaim;
