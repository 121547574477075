import api from '../../utils/services/api';

import {
  pixKeysClaimActionError,
  pixKeysClaimActionLoading,
  pixKeysClaimActionSuccess,
  detailsPixKeysClaimActionError,
  detailsPixKeysClaimActionLoading,
  detailsPixKeysClaimActionSuccess,
} from '../ducks/pixKeysClaim';

export const pixClaimKeysReceiverFetchAction = (filter = { LIMIT: 10 }) => {
  return async (dispatch) => {
    dispatch(pixKeysClaimActionLoading());

    try {
      const response = await api.get(`admin/pix/reivindicacao/solicitadas`, {
        params: {
          ...filter
        }
      });
      dispatch(pixKeysClaimActionSuccess(response));
    } catch (error) {
      dispatch(pixKeysClaimActionError(error?.response?.data));
    }
  };
};

export const pixClaimKeyReceiverDetailsFetchAction = (idClaim) => {
  return async (dispatch) => {
    dispatch(detailsPixKeysClaimActionLoading());

    try {
      const response = await api.get(
        `/admin/pix/reivindicacao/consultar/resposta/reivindicacao?ID_SOLICITACAO=${idClaim}`,
      );
      dispatch(detailsPixKeysClaimActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixKeysClaimActionError(error?.response?.data));
    }
  };
};
