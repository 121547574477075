import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './SelectOptions.styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Controller } from 'react-hook-form';

const SelectInput = ({
  options,
  control,
  name,
  width,
  height,
  variant,
  initialValue,
  disabled,
  label,
  className,
  required,
  error,
  onClick,
  autoWidth,
  shrink,
  displayRow
}) => {
  return (
    <Styled.TypeFormControl variant={variant} width={width}>
      {label && (
        <Styled.InputLabelStyled id={name} disabled={disabled} shrink={shrink}>
          {label}
        </Styled.InputLabelStyled>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue}
        render={({ field }) => (
          <Styled.SelectStyled
            {...field}
            label={label}
            height={height}
            width={displayRow ? "90%" : width }
            disabled={disabled}
            defaultValue={initialValue}
            value={field?.value}
            className={className}
            required={required}
            error={error}
            autoWidth={autoWidth}
            ref={field?.ref}
          >
            {options?.map((option) => (
              <MenuItem
                key={option.ID ?? option.id}
                value={option.ID ?? option.id}
                onClick={(e) => {
                  onClick && onClick(option);
                }}
              >
                {option.NOME ?? option.nome ?? option.description}
              </MenuItem>
            ))}
          </Styled.SelectStyled>
        )}
      ></Controller>
    </Styled.TypeFormControl>
  );
};

export default SelectInput;

SelectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  control: PropTypes.any,
  width: PropTypes.string,
  variant: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  initialName: PropTypes.any,
  initialValue: PropTypes.any,
  required: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
