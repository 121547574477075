import api from '../../utils/services/api';

import {
  detailsPixLimitRequestActionError,
  detailsPixLimitRequestActionLoading,
  detailsPixLimitRequestActionSuccess,
  pixLimitRequestActionError,
  pixLimitRequestActionLoading,
  pixLimitRequestActionSuccess,
} from '../ducks/pixLimitRequest';
import { pixTransactionsActionError } from '../ducks/pixTransactions';
import { showAlert } from '../ducks/sweetAlert';

export const pixLimitRequestFetchAction = (filter = { LIMIT: 10 }) => {
  return async (dispatch) => {
    dispatch(pixLimitRequestActionLoading());

    try {
      const response = await api.get(`admin/pix/solicitacoes/aumento_limite`, { params: filter });
      dispatch(pixLimitRequestActionSuccess(response));
    } catch (error) {
      dispatch(pixLimitRequestActionError(error?.response?.data));
    }
  };
};

export const pixLimitRequestDetailsFetchAction = (id) => {
  return async (dispatch) => {
    dispatch(detailsPixLimitRequestActionLoading());

    try {
      const response = await api.get(`admin/pix/detalhes/solicitacoes/aumento_limite?ID=${id}`);
      dispatch(detailsPixLimitRequestActionSuccess(response));
    } catch (error) {
      dispatch(detailsPixLimitRequestActionError(error?.response?.data));
    }
  };
};

export const pixLimitRequestNegadedFetchAction = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(pixLimitRequestActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(pixLimitRequestFetchAction({ LIMIT: 10, PAGE: 1 }));
    };
    try {
      const response = await api.post(`/admin/pix/recusar/aumento_limite`, params);
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Solicitação negada com sucesso!',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(pixTransactionsActionError());
      callback(error);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel negar a solicitação',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};

export const pixLimitRequestAproveFetchAction = (params, callback = () => null) => {
  return async (dispatch) => {
    dispatch(pixLimitRequestActionLoading());
    dispatch(showAlert({}));
    const closeModals = () => {
      callback();
      dispatch(pixLimitRequestFetchAction({ LIMIT: 10, PAGE: 1 }));
    };
    try {
      const response = await api.post(`/admin/pix/aprovar/aumento_limite`, params);
      callback(response);
      dispatch(
        showAlert({
          title: 'Sucesso',
          text: 'Limite aprovado com sucesso',
          icon: 'success',
          func: closeModals,
        }),
      );
    } catch (error) {
      dispatch(pixLimitRequestActionError());
      callback(error);
      dispatch(
        showAlert({
          title: 'Ops...',
          text: 'Não foi possivel aprovar o limite',
          icon: 'error',
          func: closeModals,
        }),
      );
    }
  };
};
