import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Filter from '../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import TextField from '../../../components/TextField';
import DateInput from '../../../components/DateInput';
import InputMask from '../../../components/InputMask';
import SelectOptions from '../../../components/SelectOptions';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { pixKeysFetchAction } from '../../../store/fetchActions/pixKeysActions';
import { useTheme } from 'styled-components';
import moment from 'moment';
import api from '../../../utils/services/api';
import { Grid, Typography } from '@mui/material';
import Table from '../components/Table/Table';
import getDateH from '../../../utils/functions/formatDateHora';



const Extracts = () => {
  const theme = useTheme();

  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    SEARCH: '',
    LIMIT: 10,
    DATAINICIO: '',
    DATAFIM: '',
    CHAVE: '',
    TIPOCHAVE: '',
    CONTA: '',
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));
  const [typeKey, setTypeKey] = useState([]);
  const { handleSubmit, control } = useForm({});

  const { dataPixKeys, loadingPixKeys, errorLoadingPixKeys, paginationPixKeys, errorMessagePixKeys } = useSelector(
    (state) => state.pixKeys,
  );

  useCallFilter(filterData, setFilter, pixKeysFetchAction, 0, 0);

  const filterTable = (data) => {
    console.log('data', data)
    data.DATAINICIO = dateInicial;
    data.DATAFIM = dateFinal;
    setFilterData(data);
  };

  const ColumnDadosClient = (name = '---', documentId) => {
    return (
      <Grid>
        <Typography>{name}</Typography>
        <span>{documentId?.length > 11 ? formatCNPJ(documentId) : formatCPF(documentId)}</span>
      </Grid>
    );
  };

  const pageOptions = [
    { ID: 25, NOME: 25 },
    { ID: 50, NOME: 50 },
    { ID: 100, NOME: 100 },
  ];

  useEffect(async () => {
    let typeKey = await api.get('admin/tipo-chaves-pix');

    typeKey = [ { id: 0, description: 'Todas' }, ...typeKey.data.data];

    setTypeKey(typeKey ?? []);
  }, []);


  const body = dataPixKeys?.map((key) => {
    return {
      data: [
        { info: getDateH(key.DT_INSERT)	, align: 'center' },
        { info: () => ColumnDadosClient(key.NOME, key.CPFCNPJ), align: 'left' },
        { info: key.CHAVE, align: 'left' },
        { info: key.TIPO_CHAVE, align: 'center' },
        { info: key.AGENCIA, align: 'center' },
        { info: key.CONTA, align: 'center' },
      ],
    };
  });

  const header = [
    {
      id: 'data de registro',
      name: 'DATA INSERÇÂO',
      align: 'center',
    },
    {
      id: 'Cliente',
      name: 'CLIENTE',
      align: 'left',
    },
    {
      id: 'chave',
      name: 'CHAVE',
      align: 'left',
    },
    {
      id: 'tipo chave',
      name: 'TIPO CHAVE',
      align: 'center',
    },
    {
      id: 'agencia',
      name: 'AGÊNCIA',
      align: 'center',
    },
    {
      id: 'conta',
      name: 'CONTA',
      align: 'center',
    },
  ];

  return (
    <>
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'25.042vw'} control={control} />
              <TextField label="Chave" name={'CHAVE'} width={'25.042vw'} control={control} />
              <SelectOptions
                label="Tipo de Chave"
                name={'TIPOCHAVE'}
                width={'15.042vw'}
                control={control}
                options={typeKey}
                initialValue={0}
              />
              <TextField label="Conta" name={"CONTA"}  width={'25.042vw'} control={control} />
            </FirstFilterLine>
            <FirstFilterLine style={{ justifyContent: 'flex-start' }}>
              <DateInput
                label="Data Inicial"
                name={'DATAINICIO'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATAFIM'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />

              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por Página</h5>
              <SelectOptions control={control} options={pageOptions} name={'limit'} initialValue={25} />
              <h5>Total de Registros: {paginationPixKeys?.totalResult ?? 0}</h5>
            </SecondFilterLine>
          </form>
        </Filter>
        {loadingPixKeys ? (
          <LoadingContainer>
            <CircularProgress size={'5vw'} color="inherit" />
          </LoadingContainer>
        ) : (
          <Table
            loading={loadingPixKeys}
            error={errorLoadingPixKeys}
            hasPagination={true}
            filter={filter}
            pagination={paginationPixKeys}
            setFilter={setFilter}
            errorMessage={errorMessagePixKeys}
            filterTable={filterTable}
            body={body}
            header={header}
            limit={filter.limit}
          />
        )}
      </div>
    </>
  );
};

export default Extracts;
