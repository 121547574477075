import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixKeysDonated: [],
  errorPixKeysDonated: false,
  errorLoadingPixKeysDonated: false,
  errorMessagePixKeysDonated: '',
  loadingPixKeysDonated: false,
  paginationPixKeysDonated: {},
  detailsPixKeysDonated: {},
  loadingDetailsPixKeysDonated: false,
  messageDetailsPixKeysDonated: '',
  successDetailsPixKeysDonated: false,
};

export const pixKeysDonatedActionLoading = createAction('PIX_KEYS_DONATED_ACTION_LOADING');
export const pixKeysDonatedActionSuccess = createAction('PIX_KEYS_DONATED_ACTION_SUCCESS');
export const pixKeysDonatedActionError = createAction('PIX_KEYS_DONATED_ACTION_ERROR');

export const detailsPixKeysDonatedActionLoading = createAction('DETAILS_PIX_KEYS_DONATED_ACTION_LOADING');
export const detailsPixKeysDonatedActionSuccess = createAction('DETAILS_PIX_KEYS_DONATED_ACTION_SUCCESS');
export const detailsPixKeysDonatedActionError = createAction('DETAILS_PIX_KEYS_DONATED_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixKeysDonatedActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixKeysDonated: true,
      errorPixKeysDonated: false,
    };
  },
  [pixKeysDonatedActionSuccess.type]: (state, payload) => {
    const { dados, pagination } = payload?.payload?.data;
    return {
      ...state,
      loadingPixKeysDonated: false,
      errorPixKeysDonated: false,
      dataPixKeysDonated: dados,
      paginationPixKeysDonated: pagination,
    };
  },
  [pixKeysDonatedActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixKeysDonated: false,
      errorPixKeysDonated: true,
      errorMessagePixKeysDonated: payload?.mensagem,
      dataPixKeysDonated: [],
      paginationPixKeysDonated: {},
    };
  },
  [detailsPixKeysDonatedActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailsPixKeysDonated: true,
    };
  },
  [detailsPixKeysDonatedActionSuccess.type]: (state, payload) => {
    console.log('payload', payload)
    return {
      ...state,
      loadingDetailsPixKeysDonated: false,
      successDetailsPixKeysDonated: true,
      detailsPixKeysDonated: payload?.payload?.data.data,
    };
  },
  [detailsPixKeysDonatedActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixKeysDonated: false,
      detailsPixKeysDonated: {},
      successDetailsPixKeysDonated: false,
      messageDetailsPixKeysDonated: payload?.mensagem,
    };
  },
});
